@import "@livingmap/core-ui-v2";

@import "../../scss/common.scss";
@import "../../scss/newScaleFunctions.scss";

$sub-text-content-padding: 2px 0;
$title-font-size: 18px;
$text-font-size: 14px;

$image-border-radius: 8px;
$image-margin-right: 16px;
$image-size: 96px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .textContent {
        padding: call(get-function($scaleFunction), $sub-text-content-padding);
      }

      .title {
        font-size: call(get-function($scaleFunction), $title-font-size);
      }

      .text {
        font-size: call(get-function($scaleFunction), $text-font-size);
      }

      .imageContainer {
        border-radius: call(get-function($scaleFunction), $image-border-radius);
        margin-right: call(get-function($scaleFunction), $image-margin-right);
        min-width: call(get-function($scaleFunction), $image-size);
        max-width: call(get-function($scaleFunction), $image-size);
        height: call(get-function($scaleFunction), $image-size);

        .image {
          width: call(get-function($scaleFunction), $image-size);
          height: call(get-function($scaleFunction), $image-size);
        }
      }
    }
  }
}

@mixin buildColors(
  $bg,
  $color,
  $subText-color,
  $image-bg,
  $image-border-color,
  $text-match-color
) {
  background-color: $bg;

  .textContent {
    color: $color;

    .title {
      &.titleSearch span {
        color: $text-match-color;
      }
    }

    .category,
    .floor {
      color: $subText-color;
    }

    .openingTimes {
      &.closed {
        color: #d32f2f;
      }
    }
  }

  .imageContainer {
    background: $image-bg;
    border: 2px solid $image-border-color;
  }
}

.container {
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  width: 100%;

  .textContent {
    width: 90%;
    overflow: hidden;

    .title {
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.18px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text {
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.14px;
    }
  }

  .imageContainer {
    overflow: hidden;

    .image {
      object-fit: cover;
    }
  }

  &.light {
    @include buildColors(
      $white-tone,
      $grey-tone-900,
      $grey-tone-700,
      $grey-tone-100,
      $grey-tone-200,
      $blue-tone-600
    );
  }

  &.dark {
    @include buildColors(
      $grey-tone-800,
      $grey-tone-050,
      $grey-tone-050,
      $grey-tone-700,
      $grey-tone-700,
      $blue-tone-200
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
