@import "@livingmap/core-ui-v2";

@import "../../../../scss/common.scss";
@import "../../../../scss/newScaleFunctions.scss";

$container-width: 668px;
$container-padding: 20px;
$border-radius: 24px;
$gradient-height: 60px;
$scroll-max-height: 516px;

$search-result-bottom-padding: 16px;

$no-results-title-size: 22px;
$no-results-paragraph-size: 18px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      padding: call(get-function($scaleFunction), $container-padding 0);
      border-radius: call(get-function($scaleFunction), $border-radius);
      width: call(get-function($scaleFunction), $container-width);

      &::after {
        border-radius: call(
          get-function($scaleFunction),
          0 0 $border-radius $border-radius
        );
        height: call(get-function($scaleFunction), $gradient-height);
      }

      .scrollContainer {
        max-height: call(get-function($scaleFunction), $scroll-max-height);
        padding: call(get-function($scaleFunction), 0 $container-padding);

        .listContainer {
          .searchResult:not(:last-child) {
            padding-bottom: call(
              get-function($scaleFunction),
              $search-result-bottom-padding
            );
          }
        }
      }

      .noResultsContainer {
        padding: call(get-function($scaleFunction), 0 $container-padding);

        .title {
          font-size: call(get-function($scaleFunction), $no-results-title-size);
        }

        .paragraph {
          font-size: call(
            get-function($scaleFunction),
            $no-results-paragraph-size
          );
        }
      }
    }
  }
}

.container {
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);
  font-family: "IBM Plex Sans";
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .scrollContainer {
    overflow-y: scroll;

    .listContainer {
      margin: 0;
      padding: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .noResultsContainer {
    .title {
      font-weight: 600;
      letter-spacing: 0.22px;
      margin-bottom: 2px;
    }

    .paragraph {
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.18px;
    }
  }

  &.light {
    background: $white-tone;
    color: $grey-tone-900;

    &::after {
      background: linear-gradient(
        180deg,
        rgba($white-tone, 0) 0%,
        rgba($white-tone, 0.7) 100%
      );
    }

    .noResultsContainer {
      .paragraph {
        color: $grey-tone-700;
      }
    }
  }

  &.dark {
    background: $grey-tone-800;
    color: $grey-tone-050;

    &::after {
      background: linear-gradient(
        180deg,
        rgba($grey-tone-800, 0) 0%,
        rgba($grey-tone-800, 0.7) 100%
      );
    }

    .noResultsContainer {
      .paragraph {
        color: $grey-tone-050;
      }
    }
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
