@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$size: 56px;
$svg-size: 32px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      width: call(get-function($scaleFunction), $size);
      height: call(get-function($scaleFunction), $size);

      .svg {
        width: call(get-function($scaleFunction), $svg-size);
        height: call(get-function($scaleFunction), $svg-size);
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-radius: 100px;
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);

  &.dark {
    background: $grey-tone-800;
    color: $grey-tone-050;
  }

  &.light {
    background: $white-tone;
    color: $grey-tone-900;
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
