@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-border-radius: 10px;
$content-padding: 10px 12px 12px 15px;
$container-width: 210px;

$name-font-size: 14px;

$address-font-size: 11px;
$address-margin-top: 2px;

$separator-margin: 0 4px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.container {
        width: call(get-function($scaleFunction), $container-width);
        border-radius: call(
          get-function($scaleFunction),
          $container-border-radius
        );

        .content {
          padding: call(get-function($scaleFunction), $content-padding);

          .name {
            font-size: call(get-function($scaleFunction), $name-font-size);
          }

          .address {
            font-size: call(get-function($scaleFunction), $address-font-size);
            margin-top: call(get-function($scaleFunction), $address-margin-top);

            .separator {
              margin: call(get-function($scaleFunction), $separator-margin);
            }
          }
        }
      }
    }
  }
}

@mixin buildColors($bg, $color, $accent) {
  background: $bg;

  .name {
    color: $color;
  }

  .address {
    color: $accent;
  }
}

@include buildSizes();

.container {
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);

  .content {
    font-family: "IBM Plex Sans";

    p {
      margin: 0;
    }

    .name {
      color: $grey-tone-900;
      font-weight: 500;
      letter-spacing: 0.14px;
      line-height: 140%;
    }

    .address {
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.11px;
    }
  }

  &.light {
    @include buildColors($white-tone, $grey-tone-900, $blue-tone-600);
  }

  &.dark {
    @include buildColors($grey-tone-800, $grey-tone-050, $blue-tone-200);
  }
}
