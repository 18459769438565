@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-border-radius: 12px;
$container-height: 200px;
$container-width: 400px;

$info-container-width: 296px;
$info-container-border-radius: 12px;
$info-margin: 16px;
$info-max-width: 177px;

$content-margin: 24px 16px 12px;

$name-font-size: 16px;

$description-font-size: 12px;
$description-margin-top: 2px;

$qr-code-container-width: 104px;
$qr-code-container-margin: 12px;
$qr-code-padding: 4px;
$qr-code-text-margin: 4px 0 8px 4px;
$qr-code-font-size: 9px;

$btn-size: 40px;
$btn-position: 12px;

$separator-margin: 0 4px;

$distance-font-size: 16px;
$distance-level-font-size: 12px;
$distance-icon-margin-top: 2px;

$line-height-lg: 22px;
$line-height-md: 16px;
$line-height-sm: 12px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.container {
        width: call(get-function($scaleFunction), $container-width);
        height: call(get-function($scaleFunction), $container-height);
        border-radius: call(
          get-function($scaleFunction),
          $container-border-radius
        );

        .qrCodeContainer {
          width: call(get-function($scaleFunction), $qr-code-container-width);
          margin: call(get-function($scaleFunction), $qr-code-container-margin);

          p {
            margin: call(get-function($scaleFunction), $qr-code-text-margin);
            font-size: call(get-function($scaleFunction), $qr-code-font-size);
            line-height: call(get-function($scaleFunction), $line-height-sm);
          }

          .qrCode {
            padding: call(get-function($scaleFunction), $qr-code-padding);
          }
        }

        .infoContainer {
          min-width: call(get-function($scaleFunction), $info-container-width);
          border-radius: call(
            get-function($scaleFunction),
            $info-container-border-radius
          );

          .image {
            border-radius: call(
              get-function($scaleFunction),
              $container-border-radius
            );
          }

          .content {
            margin: call(get-function($scaleFunction), $content-margin);

            &::before {
              border-bottom-left-radius: call(
                get-function($scaleFunction),
                $container-border-radius
              );
              border-bottom-right-radius: call(
                get-function($scaleFunction),
                $container-border-radius
              );
            }

            .info {
              max-width: call(get-function($scaleFunction), $info-max-width);
              margin-right: call(get-function($scaleFunction), $info-margin);

              .name {
                font-size: call(get-function($scaleFunction), $name-font-size);
                line-height: call(
                  get-function($scaleFunction),
                  $line-height-lg
                );
              }

              .description {
                p {
                  font-size: call(
                    get-function($scaleFunction),
                    $description-font-size
                  );
                  margin-top: call(
                    get-function($scaleFunction),
                    $description-margin-top
                  );
                  line-height: call(
                    get-function($scaleFunction),
                    $line-height-md
                  );
                }
              }

              .address {
                .separator {
                  margin: call(get-function($scaleFunction), $separator-margin);
                }
              }
            }

            .distanceInfo {
              margin-top: call(
                get-function($scaleFunction),
                $distance-icon-margin-top
              );

              .distance {
                font-size: call(
                  get-function($scaleFunction),
                  $distance-font-size
                );
                line-height: call(
                  get-function($scaleFunction),
                  $line-height-lg
                );
              }

              .floor {
                font-size: call(
                  get-function($scaleFunction),
                  $distance-level-font-size
                );
                line-height: call(
                  get-function($scaleFunction),
                  $line-height-md
                );
              }

              .icon {
                margin-top: call(
                  get-function($scaleFunction),
                  $distance-icon-margin-top
                );
              }
            }
          }

          .closeBtn {
            width: call(get-function($scaleFunction), $btn-size);
            height: call(get-function($scaleFunction), $btn-size);
            right: call(get-function($scaleFunction), $btn-position);
            top: call(get-function($scaleFunction), $btn-position);
          }
        }
      }
    }
  }
}

@mixin buildColors($bg, $info-bg, $color, $sub-color, $accent, $qr-text-color) {
  &.container {
    background: $bg;
    .qrCodeContainer {
      color: $qr-text-color;
    }

    .infoContainer {
      background: $info-bg;

      .content {
        .info {
          .name,
          .description {
            color: $color;
          }

          .openingTimes {
            color: $sub-color;

            span {
              color: $color;
            }
          }

          .address {
            color: $sub-color;
          }
        }

        .distanceInfo {
          color: $accent;
        }
      }
    }
  }
}

@include buildSizes();

.light {
  @include buildColors(
    $white-tone,
    $grey-tone-100,
    $grey-tone-050,
    $grey-tone-400,
    $grey-tone-050,
    $grey-tone-900
  );
}

.light.noImage {
  @include buildColors(
    $white-tone,
    $grey-tone-100,
    $grey-tone-900,
    $grey-tone-600,
    $grey-tone-900,
    $grey-tone-900
  );
}

.dark {
  @include buildColors(
    $grey-tone-800,
    $grey-tone-700,
    $grey-tone-050,
    $grey-tone-400,
    $blue-tone-200,
    $grey-tone-050
  );
}

.container {
  display: flex;
  justify-content: space-between;
  box-shadow:
    0px 4px 12px 4px rgba($black-tone, 0.04),
    0px 0px 4px 0px rgba($black-tone, 0.12);

  p {
    margin: 0;
  }

  .qrCodeContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    // TODO: need to be updated when new typography styles added
    /* label/tiny (9) */
    font-family: "IBM Plex Sans";
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.06px;

    .qrCode {
      background: $white-tone;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .infoContainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    position: relative;
    text-shadow: 0px 0px 5px rgba($black-tone, 0.1);

    .image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .content {
      display: flex;
      width: 100%;
      justify-content: space-between;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .info {
        z-index: 1;
        .name {
          @include font-title-small;
        }

        .description {
          .subtitle {
            @include font-label-small;
          }

          .address,
          .openingTimes {
            @include font-body-small;
          }
        }
      }

      .distanceInfo {
        color: $grey-tone-050;
        text-align: right;
        z-index: 1;

        .distance {
          @include font-label-large;
        }

        .floor {
          @include font-label-small-prominent;
        }

        .icon {
          &.floorUp {
            transform: rotate(-135deg);
          }

          &.floorDown {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .closeBtn {
      position: absolute;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: $white-tone;
    }
  }

  &:not(.noImage) {
    .infoContainer {
      .content {
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 60%;
          left: 0;
          background: linear-gradient(
            180deg,
            rgba($black-tone, 0) 0%,
            rgba($black-tone, 0.5) 39.45%
          );
        }
      }
    }
  }

  &.noImage {
    .infoContainer {
      text-shadow: none;
    }
  }
}

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
