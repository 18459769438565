@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$control-left-gap: 30px;
$control-right-gap: 40px;
$control-between: 12px;

$search-height-padder: 516px;
$search-results-margin: 0 0 12px;

$quick-search-tags-container-height: 103px;

$attribution-share-container-padding: 20px 0 8px;

$footer-buttons-margin-bottom: 18.5px;
$footer-button-gap-margin: 6px;

$logo-size: 6px;
$logo-margin-right: 2px;

$attribution-margin-left: 8px;
$attribution-font-size: 8px;

$toggle-bottom-margin: 12px;

$modal-body-margin-bottom: 20px;

@mixin buildCompassSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      right: call(get-function($scaleFunction), $control-right-gap);
      top: call(get-function($scaleFunction), $control-right-gap);
    }
  }
}

@mixin buildControlSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .controlContainer {
      &.#{$component-size} {
        .controlTopMargin {
          margin-top: call(get-function($scaleFunction), $control-between);
        }
      }
    }

    .controlLeftContainer {
      &.#{$component-size} {
        left: call(get-function($scaleFunction), $control-left-gap);
      }
    }

    .controlRightContainer {
      &.#{$component-size} {
        right: call(get-function($scaleFunction), $control-right-gap);
      }
    }
  }
}

@mixin buildSearchSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .searchHeightPadder {
      &.#{$component-size} {
        height: call(get-function($scaleFunction), $search-height-padder);
      }
    }

    .searchResultsContainer {
      &.#{$component-size} {
        margin: call(get-function($scaleFunction), $search-results-margin);
      }
    }
  }
}

@mixin buildMapControlSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .toggleContainer {
        > * {
          margin-bottom: call(
            get-function($scaleFunction),
            $toggle-bottom-margin
          );
        }
      }
    }
  }
}

@mixin buildFooterComponentSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .quickSearchTagsContainer {
        height: call(
          get-function($scaleFunction),
          $quick-search-tags-container-height
        );
      }

      .attributionShareContainer {
        padding: call(
          get-function($scaleFunction),
          $attribution-share-container-padding
        );

        .content {
          button {
            margin-bottom: call(
              get-function($scaleFunction),
              $footer-buttons-margin-bottom
            );

            $x: call(get-function($scaleFunction), $footer-button-gap-margin);

            &.shareButton.showLowerControls {
              transform: translateX(calc(-100% - #{$x}));
            }

            &.moveControlsButton {
              left: $x;
            }
          }

          .attribution {
            font-size: call(
              get-function($scaleFunction),
              $attribution-font-size
            );

            .logo {
              height: call(get-function($scaleFunction), $logo-size);
              width: call(get-function($scaleFunction), $logo-size);
              margin-right: call(
                get-function($scaleFunction),
                $logo-margin-right
              );
            }

            span:last-child {
              margin-left: call(
                get-function($scaleFunction),
                $attribution-margin-left
              );
            }
          }
        }
      }
    }
  }
}

@mixin buildModalBodySizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.modalBody {
        margin-bottom: call(
          get-function($scaleFunction),
          $modal-body-margin-bottom
        );
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .primaryMap {
    position: relative;
    flex: 1;
    z-index: 2;

    .primaryCompassContainer {
      position: absolute;

      @include buildCompassSizes();
    }

    .controlContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      z-index: 1;
      bottom: 0;
    }

    .diagnosticsBody {
      white-space: pre-wrap;
      text-align: left;
    }

    @include buildControlSizes();
    @include buildModalBodySizes();
  }

  .searchWrapper {
    &.accessibleHeight {
      .animatedWrapper {
        transform: translateY(0);
      }
    }

    .animatedWrapper {
      transform: translateY(21%);
    }

    .searchHeightPadder {
      display: flex;
      align-items: flex-end;
    }

    .searchResultsContainer {
      z-index: 3;
    }

    @include buildSearchSizes();
  }

  .mapControlsContainer {
    position: absolute;
    pointer-events: none;
    z-index: 1;
    bottom: 0;
    width: 100%;
    margin: 0 auto;

    .toggleSearchContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .toggleContainer {
        position: absolute;
        width: 100%;

        > * {
          margin: 0 auto;
          left: 0;
          right: 0;
          position: absolute;
          bottom: 100%;
          pointer-events: all;
        }
      }

      .hidden {
        visibility: hidden;
      }

      .searchContainer {
        pointer-events: none;
        margin: 0 auto;

        button {
          pointer-events: all;
        }
      }
    }

    @include buildMapControlSizes();
  }

  .footerContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    .quickSearchTagsContainer {
      pointer-events: none;
      background-image: linear-gradient(#ffffff00, #ffffffe5);

      button {
        pointer-events: all;
      }
    }

    .attributionShareContainer {
      pointer-events: all;
      width: 100%;
      color: #212121;
      font-family: "IBM Plex Sans";
      background: #ffffffe5;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .buttons {
          display: flex;
          flex-direction: row;

          &.showLowerControls {
            width: 100%;
            transform: translateX(50%);
          }

          .moveControlsButton {
            position: absolute;
          }
        }

        .attribution {
          font-weight: 450;
          letter-spacing: 0.08px;
          line-height: 24px;
          margin: 0 auto;
        }
      }
    }

    @include buildFooterComponentSizes();
  }
}

@include scale-for-4k-media-query {
  .container {
    .primaryMap {
      .primaryCompassContainer {
        @include buildCompassSizes($doubled-sizes);
      }

      @include buildControlSizes($doubled-sizes);
      @include buildModalBodySizes($doubled-sizes);
    }

    .mapControlsContainer {
      @include buildMapControlSizes($doubled-sizes);
    }

    .searchWrapper {
      @include buildSearchSizes($doubled-sizes);
    }

    .footerContainer {
      @include buildFooterComponentSizes($doubled-sizes);
    }
  }
}
