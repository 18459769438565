@import "@livingmap/core-ui-v2";

@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-height: 48px;
$container-width: 96px;
$container-border-radius: 99px;

$btn-border-radius: 99px;
$btn-padding: 10px;
$btn-size: 48px;
$btn-svg-size: 16px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      height: call(get-function($scaleFunction), $container-height);
      width: call(get-function($scaleFunction), $container-width);

      .button {
        padding: call(get-function($scaleFunction), $btn-padding);
        height: call(get-function($scaleFunction), $btn-size);
        width: call(get-function($scaleFunction), $btn-size);
      }

      .svg {
        width: call(get-function($scaleFunction), $btn-svg-size);
        height: call(get-function($scaleFunction), $btn-svg-size);
      }
    }
  }
}

@mixin buildColors($color, $bg, $active-bg) {
  background: $bg;
  box-shadow:
    0px 4px 12px 5px rgba(0, 0, 0, 0.05),
    0px 0px 12px 0px rgba(0, 0, 0, 0.15);

  .svg {
    color: $color;
  }

  .button {
    &:active {
      background-color: $active-bg;
    }

    &.disabled {
      .svg {
        color: $grey-tone-500;
      }
    }
  }
}

.container {
  display: flex;
  border-radius: $container-border-radius;

  .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $btn-border-radius;
  }

  &.dark {
    @include buildColors(
      $grey-tone-050,
      $grey-tone-800,
      rgba($white-tone, 0.12)
    );
  }

  &.light {
    @include buildColors(
      $grey-tone-900,
      $white-tone,
      rgba($grey-tone-900, 0.08)
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
